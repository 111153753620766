import { Heading, VStack, Grid } from '@chakra-ui/react';
import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { HolidayPool } from './holidayPools/holidayPool';
import {
  selectIsUsersLoading,
  selectUser,
} from '../../../../redux/users/selectors';
import { fetchUserDetails } from '../../../../redux/users/thunks';
import { AddHolidayPool } from './holidayPools/AddHolidayPool';
import { DisplayUserData } from './userData/DisplayUserData';
import { AddContract } from './Contracts/AddContract';
import { CenteredSpinner } from '../../../../components/common/CenteredSpinner';
import { DisplayHoliday } from './holidays/DisplayHoliday';
import { AddHoliday } from './holidays/AddHoliday';
import { DisplayContract } from './Contracts/contract';
import { AddSocialSecurityBenefits } from './SocialSecurityBenefits/AddSocialSecurityBenefits';
import { DisplaySocialSecurityBenefits } from './SocialSecurityBenefits/DisplaySocialSecurityBenefits';
import { AddDelegation } from './Delegation/AddDelegation';
import { DisplayDelegation } from './Delegation/delegation';
import { AddAdditionalHolidayTypeForm } from './AdditionaHoliday/AddAdditionalHolidayTypeForm';
import { AddAdditionalHolidayPool } from './AdditionaHoliday/AddAdditionalHolidayPool';
import { DisplayAdditionalHolidayPool } from './AdditionaHoliday/DisplayAdditionalHolidayPool';

export const Profile: FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const numberUserId = Number(userId);
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser());
  const isLoading = useAppSelector(selectIsUsersLoading);

  useEffect(() => {
    void dispatch(fetchUserDetails(numberUserId));
  }, [dispatch, numberUserId]);

  if (isLoading) return <CenteredSpinner />;

  return (
    <VStack
      alignItems="flex-start"
      p={['0.75rem', null, null, null, null, '4rem']}
    >
      <DisplayUserData user={user} />
      <Heading as="h1" size="md" py="5">
        UMOWA
      </Heading>
      <Grid
        w="100%"
        gap={8}
        templateColumns={['1fr', null, null, 'minmax(0, 1fr) minmax(0, 1fr)']}
        pb="5"
      >
        <AddContract userId={numberUserId} />
        <DisplayContract userId={numberUserId} />
      </Grid>
      <Heading as="h1" size="md" py="5">
        PULA URLOPOWA
      </Heading>
      <Grid
        gap={8}
        w="100%"
        templateColumns={['1fr', null, null, 'minmax(0, 1fr) minmax(0, 1fr)']}
        pb="5"
      >
        <AddHolidayPool userId={numberUserId} />
        <HolidayPool userId={numberUserId} />
      </Grid>

      <Heading as="h1" size="md" py="5">
        WNIOSKI URLOPOWE
      </Heading>
      <Grid
        w="100%"
        gap={8}
        templateColumns={['1fr', null, null, 'minmax(0, 1fr) minmax(0, 1fr)']}
        pb="5"
      >
        <AddHoliday userId={numberUserId} />
        <DisplayHoliday userId={numberUserId} />
      </Grid>
      <Heading as="h1" size="md" py="5">
        ŚWIADCZENIA ZUS
      </Heading>
      <Grid
        w="100%"
        gap={8}
        templateColumns={['1fr', null, null, 'minmax(0, 1fr) minmax(0, 1fr)']}
        pb="5"
      >
        <AddSocialSecurityBenefits userId={numberUserId} />
        <DisplaySocialSecurityBenefits userId={numberUserId} />
      </Grid>
      <Heading as="h1" size="md" py="5">
        DELEGACJE
      </Heading>
      <Grid
        w="100%"
        gap={8}
        templateColumns={['1fr', null, null, 'minmax(0, 1fr) minmax(0, 1fr)']}
        pb="5"
      >
        <AddDelegation userId={numberUserId} />
        <DisplayDelegation userId={numberUserId} />
      </Grid>
      <Heading as="h1" size="md" py="6">
        DODAJ TYP DODATKOWEGO URLOPU
      </Heading>
      <Grid
        w="100%"
        gap={8}
        templateColumns={['1fr', null, null, 'minmax(0, 1fr) minmax(0, 1fr)']}
        pb="5"
      >
        <AddAdditionalHolidayTypeForm />
      </Grid>
      <Heading as="h1" size="md" py="6">
        DODAJ DODATKOWĄ PULĘ URLOPOWĄ
      </Heading>
      <Grid
        w="100%"
        gap={8}
        templateColumns={['1fr', null, null, 'minmax(0, 1fr) minmax(0, 1fr)']}
        pb="5"
      >
        <AddAdditionalHolidayPool userId={numberUserId} />
        <DisplayAdditionalHolidayPool userId={numberUserId} />
      </Grid>
    </VStack>
  );
};

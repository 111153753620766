import { VStack, GridItem, Button, Grid, Box } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { Validate, useFormContext } from 'react-hook-form';
import {
  DateIcon,
  TypeIcon,
  HolidayInfoIcon,
} from '../../../../../components/common/Icons';
import { DatePicker } from '../../../../../components/form/inputs/DatePicker';
import { options } from '../../../../../types/socialSecurityBenefitsTypes';
import { FormSelect } from '../../../../../components/form/inputs/Select';
import { requiredValidation } from '../../../../../utils/validation';
import {
  getYearStart,
  getYearEnd,
  countWorkday,
} from '../../../../../utils/date';
import { TextInput } from '../../../../../components/form/inputs/TextInput';
import {
  SocialSecurityBenefitsAddModel,
  SocialSecurityBenefitsType,
} from '../../../../../redux/socialSecurityBenefits';
import { useAppDispatch, useAppSelector } from 'src/store';
import { fetchAdditionalHolidayTypes } from 'src/redux/additionalHoliday/thunks';
import { selectUserAdditionalHolidaysPool } from 'src/redux/additionalHoliday/selectors';
import { AdditionalUserHolidayPool } from 'types/additionalHoliday';
import { Nullable } from 'utils/consts';
import { fetchPublicHolidays } from 'src/redux/publicHolidays/thunks';
import { selectPublicHolidays } from 'src/redux/publicHolidays/selectors';
import {} from 'utils/string';

interface Props {
  isEditing?: boolean;
  onOpen?: () => void;
}

type additionalHolidayInterface = {
  id: number;
  type: string;
  typeId: number;
  year: number;
  leftDays: number;
  totalDays: number;
};

const additional_holiday_prefix = 'additional_holiday ';

export const FormSocialSecurityBenefits: FC<Props> = ({ isEditing }) => {
  const { watch, setValue } = useFormContext<SocialSecurityBenefitsAddModel>();
  const dispatch = useAppDispatch();
  const calculateUserAdditionalHolidayPool = useAppSelector(
    selectUserAdditionalHolidaysPool
  );
  const publicHolidays = useAppSelector(selectPublicHolidays);

  const additionalHolidayTypes = calculateUserAdditionalHolidayPool.map(
    (value: AdditionalUserHolidayPool) => {
      const data: additionalHolidayInterface = {
        id: value.id,
        type: SocialSecurityBenefitsType.additional_holiday,
        typeId: value.typeId,
        leftDays: parseInt(value.leftDays),
        totalDays: parseInt(value.totalDays),
        year: parseInt(value.year),
      };
      return {
        label: `${value.type} r.${value.year} (${value.leftDays} z ${value.totalDays})`,
        value: additional_holiday_prefix + JSON.stringify(data),
      };
    }
  );

  const { getValues } = useFormContext();

  // function to validate if the additional hollyday pool is corectly fill
  const formSocialSecurityValidation = {
    validate: {
      notEnoughtDays: (() => {
        const formData = getValues() as SocialSecurityBenefitsAddModel;
        const startYear = formData.dateFrom;
        const endYear = formData.dateTo;
        let additionalHolidayData: Nullable<additionalHolidayInterface> = null;
        if (
          formData.typeTemp.startsWith(additional_holiday_prefix) &&
          startYear &&
          endYear
        ) {
          additionalHolidayData = JSON.parse(
            formData.typeTemp.substring(additional_holiday_prefix.length)
          ) as additionalHolidayInterface;
          // check if the start and end date are from the same year as
          // the selected hollyday pool
          if (
            additionalHolidayData.year != startYear?.getFullYear() ||
            additionalHolidayData.year != endYear?.getFullYear()
          ) {
            return false;
          }
          // check if number of avaliabie days match the number of working
          // days in the selected date range.
          if (
            additionalHolidayData.leftDays <
            countWorkday(endYear, startYear, publicHolidays)
          ) {
            return false;
          }
          setValue('type', SocialSecurityBenefitsType.additional_holiday);
        } else {
          setValue('type', formData.typeTemp);
        }
        return true;
      }) as Validate<string>,
    },
  };

  const yearNow = new Date().getFullYear();
  const [dateToMin, setDateToMin] = useState(getYearStart(yearNow - 1));
  const [dateFromMin, setDateFromMin] = useState(getYearStart(yearNow - 1));
  const [dateToMax, setDateToMax] = useState(getYearEnd(yearNow + 1));
  const [dateFromMax, setDateFromMax] = useState(getYearEnd(yearNow + 1));

  useEffect(() => {
    const subscription = watch((value, { type, name }) => {
      if (
        name === 'dateFrom' &&
        type === 'change' &&
        value.dateFrom != null &&
        value.dateTo != null &&
        value.dateFrom > value.dateTo
      ) {
        setValue('dateTo', value.dateFrom);
      }

      if (
        name === 'typeTemp' &&
        type === 'change' &&
        value.typeTemp != null &&
        value.typeTemp.startsWith(additional_holiday_prefix)
      ) {
        const additionalHolidayData: additionalHolidayInterface = JSON.parse(
          value.typeTemp.substring(additional_holiday_prefix.length)
        ) as additionalHolidayInterface;
        setDateToMin(getYearStart(additionalHolidayData.year));
        setDateFromMin(getYearStart(additionalHolidayData.year));
        setDateToMax(getYearEnd(additionalHolidayData.year));
        setDateFromMax(getYearEnd(additionalHolidayData.year));
        setValue('additionalHolidayTypeId', additionalHolidayData.typeId);
        setValue('additionalHolidayId', additionalHolidayData.id);
        setValue('type', SocialSecurityBenefitsType.additional_holiday);
      } else if (name === 'typeTemp' && type === 'change') {
        setValue('additionalHolidayTypeId', undefined);
        setValue('additionalHolidayId', undefined);
        setValue('type', value.typeTemp || '');
        setDateToMin(getYearStart(yearNow - 1));
        setDateFromMin(getYearStart(yearNow - 1));
        setDateToMax(getYearEnd(yearNow + 1));
        setDateFromMax(getYearEnd(yearNow + 1));
      }

      if (
        name === 'dateFrom' &&
        type === 'change' &&
        value.dateFrom != null &&
        dateToMin < value.dateFrom
      ) {
        setDateToMin(value.dateFrom);
      }
      if (
        name === 'dateTo' &&
        type === 'change' &&
        value.dateTo != null &&
        dateFromMax < value.dateTo
      ) {
        setDateFromMax(value.dateTo);
      }
      if (
        value.typeTemp != null &&
        value.typeTemp.startsWith(additional_holiday_prefix)
      ) {
        const additionalHolidayData: additionalHolidayInterface = JSON.parse(
          value.typeTemp.substring(additional_holiday_prefix.length)
        ) as additionalHolidayInterface;
        if (name === 'dateTo' && type === 'change' && value.dateTo === null) {
          setDateFromMax(getYearEnd(additionalHolidayData.year));
        }
        if (
          name === 'dateFrom' &&
          type === 'change' &&
          value.dateFrom === null
        ) {
          setDateToMin(getYearStart(additionalHolidayData.year));
        }
      } else {
        if (name === 'dateTo' && type === 'change' && value.dateTo === null) {
          setDateFromMax(getYearEnd(yearNow + 1));
        }
        if (
          name === 'dateFrom' &&
          type === 'change' &&
          value.dateFrom === null
        ) {
          setDateToMin(getYearStart(yearNow - 1));
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue, yearNow, dateFromMax, dateToMin]);

  useEffect(() => {
    void dispatch(fetchAdditionalHolidayTypes());
    void dispatch(fetchPublicHolidays());
  }, [dispatch]);

  return (
    <VStack
      alignItems="flex-start"
      width={isEditing ? undefined : ['100%', null, 'sm']}
    >
      <Grid
        paddingLeft={isEditing ? undefined : 4}
        alignItems={isEditing ? 'center' : 'inherit'}
        borderLeft={isEditing ? undefined : '1px solid'}
        borderColor={isEditing ? undefined : 'active'}
        templateColumns="0.1fr 1fr 1fr"
        columnGap={3}
        rowGap={isEditing ? 8 : 4}
        width={isEditing ? undefined : ['100%', null, 'sm']}
      >
        <GridItem color="active">
          <DateIcon />
        </GridItem>
        <DatePicker
          label="data od"
          name="dateFrom"
          minDate={dateFromMin}
          maxDate={dateFromMax}
          registerProps={requiredValidation}
          isStandalone
        />
        <DatePicker
          label="data do"
          name="dateTo"
          minDate={dateToMin}
          maxDate={dateToMax}
          isStandalone
        />
        <GridItem color="active">
          <TypeIcon />
        </GridItem>
        <GridItem colSpan={2}>
          <FormSelect
            name="typeTemp"
            isStandalone
            label="rodzaj"
            registerProps={{
              ...requiredValidation,
              ...formSocialSecurityValidation,
            }}
            options={[...options, ...additionalHolidayTypes]}
          />
        </GridItem>
        <GridItem color="active">
          <HolidayInfoIcon />
        </GridItem>
        <GridItem colSpan={2}>
          <TextInput name="addInfo" isStandalone label="dodatkowe uwagi" />
        </GridItem>
      </Grid>
      <Box paddingTop={2} alignSelf={isEditing ? 'center' : 'flex-end'}>
        <Button type="submit" variant="submitForm">
          Zapisz
        </Button>
      </Box>
    </VStack>
  );
};

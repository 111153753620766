import {
  Flex,
  Grid,
  IconButton,
  ListIcon,
  Tooltip,
  theme,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import { FaTrashAlt } from 'react-icons/fa';
import { FC, useEffect, useState } from 'react';
import { InfoIcon, TypeIcon } from '../../../../../components/common/Icons';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { DateIcon } from '../../../../../components/common/Icons';
import { UserProfileListElement } from '../../../../../components/UserProfileList/UserProfileListElement';
import { UserProfileList } from '../../../../../components/UserProfileList';
import { CenteredSpinner } from '../../../../../components/common/CenteredSpinner';
import { DeleteAdditionalHolidayPool } from './DeleteAdditionalHolidayPool';
import { getAdditionalHolidayUserPool } from 'src/redux/additionalHoliday/thunks';
import {
  selectAreAdditionalHolidaysLoading,
  selectUserAdditionalHolidaysPool,
} from 'src/redux/additionalHoliday/selectors';

interface Props {
  userId: number;
}
export const DisplayAdditionalHolidayPool: FC<Props> = ({ userId }) => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectAreAdditionalHolidaysLoading);
  const calculateUserAdditionalHolidayPool = useAppSelector(
    selectUserAdditionalHolidaysPool
  );

  const deleteModalDisclosure = useDisclosure();
  const [additionalHolidayPoolIdToDelete, setAdditionalHolidayPoolIdToDelete] =
    useState<number>();

  const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.lg})`);

  useEffect(() => {
    try {
      if (userId) void dispatch(getAdditionalHolidayUserPool({ user: userId }));
    } catch (error) {
      console.error(error);
    }
  }, [dispatch, userId]);

  const handleOnDelete = (id: number) => {
    setAdditionalHolidayPoolIdToDelete(id);
  };

  if (calculateUserAdditionalHolidayPool.length < 1 && isMobile) return null;

  return isLoading ? (
    <CenteredSpinner />
  ) : (
    <UserProfileList>
      {additionalHolidayPoolIdToDelete != null && (
        <DeleteAdditionalHolidayPool
          userId={userId}
          additionalHolidayPoolId={additionalHolidayPoolIdToDelete}
          onClose={() => {
            deleteModalDisclosure.onClose();
            setAdditionalHolidayPoolIdToDelete(undefined);
          }}
        />
      )}
      {calculateUserAdditionalHolidayPool?.map(
        ({ id, year, totalDays, type, additionalInfo, leftDays }) => (
          <UserProfileListElement key={id} id={id}>
            <Grid
              w="100%"
              templateColumns={[
                '0.5fr 2fr 1.2fr 1.5fr 0.5fr',
                null,
                null,
                null,
                null,
                '0.5fr 2fr 1.2fr 1.5fr 0.5fr',
              ]}
              fontSize="size4"
            >
              <Flex alignItems="center" pr={10} gap="10%">
                <ListIcon as={DateIcon} id={`${id}`} />
                {year}
              </Flex>
              <Flex alignItems="center" gap="5%" pr={5}>
                <ListIcon as={TypeIcon} />
                {type}
              </Flex>
              <Flex alignItems="center" pr={2}>
                pula: {totalDays}
              </Flex>
              <Flex alignItems="center">zostało: {leftDays}</Flex>
              <Flex alignItems="center" minWidth="22px">
                <Tooltip
                  key={1}
                  label={additionalInfo}
                  placement="bottom-start"
                  fontSize="size6"
                >
                  <span>
                    <ListIcon as={InfoIcon} justifyContent="center" />
                  </span>
                </Tooltip>
              </Flex>
            </Grid>
            <IconButton
              icon={<FaTrashAlt />}
              aria-label={'Delete'}
              ml={5}
              size="xs"
              variant="iconButton"
              onClick={() => handleOnDelete(id)}
            />
          </UserProfileListElement>
        )
      )}
    </UserProfileList>
  );
};

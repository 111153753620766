import {
  addDays,
  addYears,
  endOfYear,
  format,
  startOfYear,
  subDays,
  subMonths,
  subYears,
  endOfDay,
  startOfDay,
  isBefore,
  isSunday,
  isSaturday,
  isSameDay,
} from 'date-fns';
import { PublicHolidayModel } from 'src/redux/publicHolidays';

export const dateFormats = {
  main: 'dd.MM.yyyy',
  backend: 'yyyy-MM-dd',
  backendLastDay: 'yyyy-12-31',
  year: 'yyyy',
};

export const dateTimeFormats = {
  main: 'dd.MM.yyyy HH:mm',
};

export const formatDate = (date: string | Date | number) =>
  format(new Date(date), dateFormats.main);
export const formatDateTime = (date: string | Date | number) =>
  format(new Date(date), dateTimeFormats.main);
export const formatToYear = (date: Date | number) =>
  format(date, dateFormats.year);
export const formatToBackendDate = (date: Date | number) =>
  format(date, dateFormats.backend);
export const formatToLastDay = (date: Date | number) =>
  format(date, dateFormats.backendLastDay);

export const getLastDayOfNextYear = () =>
  formatToLastDay(addYears(new Date(), 1));
export const getLastMonth = () => subMonths(new Date(), 1);
export const getLastYear = () => formatToYear(subYears(new Date(), 1));
export const getYear10YearsAgo = () => subYears(new Date(), 10);
export const getYearStart = (year: number) => startOfYear(new Date(year, 0));
export const getYearEnd = (year: number) => endOfYear(new Date(year, 0));

export const correctToCalendarDate = (date: string | Date) =>
  formatToBackendDate(addDays(new Date(date), 1));
export const correctFromCalendarDate = (date: Date) => subDays(date, 1);
export const correctFromCalendarDateTo = (date: Date) =>
  formatDate(subDays(new Date(date), 1));
/**
 *
 * @param date Date to sheck if is one of holy day
 * @param publicHolidays List of holydays. get from 'useAppSelector(selectPublicHolidays)';
 * @returns
 */
export const isHoliday = (date: Date, publicHolidays: PublicHolidayModel[]) => {
  let _isHoliday = false;
  publicHolidays.forEach((holiday) => {
    if (isSameDay(date, holiday.dateFrom) || isSameDay(date, holiday.dateTo)) {
      _isHoliday = true;
    }
  });
  return _isHoliday;
};
/**
 * Function that retur number of working day betwean 2 dates.
 * @param dateTo End of the period
 * @param dateFrom start of the period
 * @param publicHolidays list of days should not be count as working days.
 *    List of holydays. get from 'useAppSelector(selectPublicHolidays)';
 * @returns number of working day's int the time range.
 */
export const countWorkday = (
  dateTo: Date | number,
  dateFrom: Date | number,
  publicHolidays: PublicHolidayModel[]
) => {
  // return differenceInBusinessDays( endOfDay(addDays(dateTo, 1)), startOfDay(dateFrom));
  let iDate: Date = startOfDay(dateFrom);
  const endDate = endOfDay(dateTo);
  let workdayCounter = 0;
  while (isBefore(iDate, endDate)) {
    if (isSaturday(iDate) || isSunday(iDate)) {
      iDate = addDays(iDate, 1);
    } else if (isHoliday(iDate, publicHolidays)) {
      iDate = addDays(iDate, 1);
    } else {
      iDate = addDays(iDate, 1);
      workdayCounter++;
    }
  }
  return workdayCounter;
};

import { Button, GridItem, VStack, Box, Grid } from '@chakra-ui/react';
import {
  TypeIcon,
  HolidayIcon,
  HolidayInfoIcon,
} from '../../../../../components/common/Icons';
import { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { requiredValidation } from '../../../../../utils/validation';
import { AdditionalHolidayTypeFormProps } from 'types/forms';
import { NumberInput } from 'components/form/inputs/NumberInput';
import { TextInput } from 'components/form/inputs/TextInput';

export const AdditionalHolidayTypeForm: FC = () => {
  const { control } = useFormContext<AdditionalHolidayTypeFormProps>();
  useWatch({ control });

  return (
    <VStack alignItems="flex-start" width={['100%', null, 'sm']}>
      <Grid
        paddingLeft={4}
        alignItems={'inherit'}
        borderLeft={'1px solid'}
        borderColor={'active'}
        templateColumns="0.1fr 1fr 1fr"
        columnGap={3}
        rowGap={4}
        width={['100%', null, 'sm']}
      >
        <GridItem color="active">
          <TypeIcon />
        </GridItem>
        <GridItem>
          <TextInput
            name="type"
            isStandalone
            label="typ urlopu"
            registerProps={requiredValidation}
          />
        </GridItem>
        <GridItem color="active" gridRow={2}>
          <HolidayIcon />
        </GridItem>
        <GridItem colSpan={2}>
          <NumberInput
            label="pula dodatkowych dni"
            name="days"
            registerProps={requiredValidation}
            isStandalone
          />
        </GridItem>
        <GridItem color="active">
          <HolidayInfoIcon />
        </GridItem>
        <GridItem colSpan={2}>
          <TextInput
            label="informacje dodatkowe"
            name="additionalInfo"
            registerProps={requiredValidation}
            isStandalone
          />
        </GridItem>
      </Grid>
      <Box paddingTop={2} alignSelf={'flex-end'}>
        <Button type="submit" variant="submitForm">
          Zapisz
        </Button>
      </Box>
    </VStack>
  );
};

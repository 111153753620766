import { ThunkError } from '../types/thunk';
import { displayErrorToast } from './toasts';

export enum ErrorCode {
  InvalidToken = 'invalid_token',
  NotAuthenticated = 'not_authenticated',
  TokenUsed = 'token_used',
  TokenMismatch = 'token_mismatch',
  // Field Validations
  HolidayOverlap = 'overlapping holiday exists',
  PublicHolidayOverlap = 'overlapping public holiday exist',
  DelegationOverlap = 'overlapping delegation exists',
  BenefitsOverlap = 'overlapping benefit exists',
  DateFromExceededDateTo = 'date_from exceeded date_to',
  OneHolidayPoolPerYear = 'each user can only have one holiday pool per each year',

  // Social Security Benefits Validations
  UserIdAndHolidayPoolIdMissmatch = 'The additionalHoliday pool does not belong to that user',
  ToSmallPool = 'The pool is (\\d+) days, (\\d+) days is already used',
}

const ErrorCodeRegEx: { [key: string]: RegExp } = {
  [ErrorCode.ToSmallPool]: new RegExp(ErrorCode.ToSmallPool, 'gm'),
};

const toastErrors: { [key: string]: string } = {
  [ErrorCode.DateFromExceededDateTo]:
    'Data początkowa urlopu nie może być większa od daty końcowej',
  [ErrorCode.HolidayOverlap]:
    'Urlopy kolidują ze sobą, w razie problemów skontaktuj się z działem People',
  [ErrorCode.PublicHolidayOverlap]:
    'Urlop publiczny został już dodany w tym terminie',
  [ErrorCode.DelegationOverlap]: 'Delegacje kolidują ze sobą, zweryfikuj daty',
  [ErrorCode.BenefitsOverlap]:
    'Świadczenia ZUS kolidują ze sobą, zweryfikuj daty',
  [ErrorCode.InvalidToken]: 'Token jest nieprawidłowy',
  [ErrorCode.TokenUsed]: 'Token został już użyty',
  [ErrorCode.TokenMismatch]: 'Token nie istnieje',
  [ErrorCode.OneHolidayPoolPerYear]:
    'Użytkownik może mieć tylko jedną pulę urlopów na rok',
  [ErrorCode.UserIdAndHolidayPoolIdMissmatch]:
    'Coś porszło nie tak. odżwierz stronę i sprubuj jescze raz.',
  [ErrorCode.ToSmallPool]: 'Pula urlopowa ma {1} dni, {2} dni już sa użyte.',
  generalError: 'Wystąpił błąd',
};

export const handleError = (error: ThunkError) => {
  const errorCode =
    error?.response?.data?.details?.message?.[0] ?? error?.response?.data?.code;

  if (handleErrorDynmamicMessage(errorCode)) {
    // it was dynamic error code from server.
    console.error(error);
    return;
  }

  switch (errorCode) {
    case ErrorCode.HolidayOverlap:
    case ErrorCode.PublicHolidayOverlap:
    case ErrorCode.DelegationOverlap:
    case ErrorCode.BenefitsOverlap:
    case ErrorCode.DateFromExceededDateTo:
    case ErrorCode.InvalidToken:
    case ErrorCode.TokenUsed:
    case ErrorCode.TokenMismatch:
    case ErrorCode.OneHolidayPoolPerYear:
      displayErrorToast({ title: toastErrors[errorCode] });
      break;
    default:
      displayErrorToast({ title: toastErrors.generalError });
      break;
  }

  console.error(error);
};

/**
 * Handle errors that have dynamic content message. And can be match by Regular Expresion
 * It replace message with more user friendy text. Data form the message are extract using
 * RegEx groups parts soround with () and return in the output of RegEx.exec. Then pass to
 * format function that can put thouse value in new message.
 * @param message
 * @returns
 * null - if the message has not been handeled. \
 * ErrorCode - ErrorCode of the handled message.
 */
const handleErrorDynmamicMessage = (message: string | undefined) => {
  let errorCode: ErrorCode | null = null;

  if (message) {
    for (const key in ErrorCodeRegEx) {
      const regEx = ErrorCodeRegEx[key];
      const regExMatchOutput = regEx.exec(message);
      if (regExMatchOutput && Object.hasOwnProperty.call(toastErrors, key)) {
        errorCode = key as ErrorCode;
        const message: string = toastErrors[key].format(regExMatchOutput);
        displayErrorToast({ title: message });
      }
    }
  }

  return errorCode;
};

export const getHolidayActionErrorVerboseMessage = (error: ThunkError) => {
  const errorCode =
    error?.response?.data?.details?.message?.[0] ?? error?.response?.data?.code;

  switch (errorCode) {
    case ErrorCode.InvalidToken:
    case ErrorCode.TokenUsed:
    case ErrorCode.TokenMismatch:
      return toastErrors[errorCode];
    default:
      return toastErrors.generalError;
  }
};

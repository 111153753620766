import { Button, GridItem, VStack, Box, Grid } from '@chakra-ui/react';
import { DateIcon, HolidayIcon } from '../../../../../components/common/Icons';
import { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormSelect } from '../../../../../components/form/inputs/Select';
import { requiredValidation } from '../../../../../utils/validation';
import { SelectOption } from '../../../../../types/SelectOptions';
import { selectIsHolidayPoolLoading } from '../../../../../redux/holidayPools/selectors';
import { useAppSelector } from '../../../../../store';
import { AddAdditionalUserHolidayPoolForm } from 'types/additionalHoliday';

interface Props {
  yearOptions: SelectOption[];
  typeOptions: SelectOption[];
}

export const AdditionalHolidayPoolForm: FC<Props> = ({
  yearOptions,
  typeOptions,
}) => {
  const isLoading = useAppSelector(selectIsHolidayPoolLoading);
  const { control } = useFormContext<AddAdditionalUserHolidayPoolForm>();
  useWatch({ control });

  return (
    <VStack alignItems="flex-start" width={['100%', null, 'sm']}>
      <Grid
        paddingLeft={4}
        alignItems={'inherit'}
        borderLeft={'1px solid'}
        borderColor={'active'}
        templateColumns="0.1fr 1fr 1fr"
        columnGap={3}
        rowGap={4}
        width={['100%', null, 'sm']}
      >
        <GridItem color="active">
          <DateIcon />
        </GridItem>
        <GridItem>
          <FormSelect
            name="year"
            isStandalone
            label="rok"
            registerProps={requiredValidation}
            options={yearOptions}
          />
        </GridItem>
        <GridItem color="active" gridRow={2}>
          <HolidayIcon />
        </GridItem>
        <GridItem colSpan={2}>
          <FormSelect
            name="holiday"
            isStandalone
            label="typ urlopu"
            registerProps={requiredValidation}
            options={typeOptions}
          />
        </GridItem>
      </Grid>
      <Box paddingTop={2} alignSelf={'flex-end'}>
        <Button isDisabled={isLoading} type="submit" variant="submitForm">
          Zapisz
        </Button>
      </Box>
    </VStack>
  );
};

import { createSlice, isFulfilled } from '@reduxjs/toolkit';
import {
  addUserSocialSecurityBenefits,
  fetchUserSocialSecurityBenefits,
  deleteUserSocialSecurityBenefits,
  editUserSocialSecurityBenefits,
  fetchDaysSumForUserAndYear,
  fetchAllUsersSocialSecurityBenefits,
} from './thunks';

export enum SocialSecurityBenefitsType {
  l4 = 'l4',
  care = 'care',
  maternal = 'maternal',
  paternal = 'paternal',
  parental = 'parental',
  child_care = 'child_care',
  additional_holiday = 'other',
  rehabilitation_allowance = 'rehabilitation_allowance',
}
export interface SocialSecurityBenefits {
  id: number;
  dateFrom: string;
  dateTo: string;
  type: SocialSecurityBenefitsType | null;
  addInfo: string;
  isCancelled: boolean;
  additionalHolidayId?: number;
}
export interface SocialSecurityBenefitsWithUserModel
  extends SocialSecurityBenefits {
  id: number;
  firstName: string;
  lastName: string;
  user: number;
  isHalfDay?: boolean;
  isAcceptedByEmployee: boolean;
  isAcceptedByAdmin: boolean;
}

export interface SocialSecurityBenefitsAddModel {
  user: number;
  dateTo: Date | null;
  dateFrom: Date | null;
  type: string;
  /**
   * 'typeTemp' temprary praperty to store selected value from dropdow. Base on
   * value in 'typeTemp' prapertys 'additionalHolidayTypeId', 'additionalHolidayId',
   * 'type' is beeing calculated
   */
  typeTemp: string;
  additionalHolidayTypeId?: number;
  additionalHolidayId?: number;
  addInfo: string | null;
}
export interface SocialSecurityBenefitsEditModel {
  id: number;
  user: number;
  dateTo?: Date | null;
  dateFrom?: Date | null;
  type?: string;
  addInfo?: string | null;
}
export interface SocialSecurityBenefitsModel {
  id: number;
  dateFrom: string;
  dateTo: string;
  type: SocialSecurityBenefitsType;
  addInfo: string;
}
export interface DeleteSocialSecurityBenefit {
  id: number;
  isCancelled?: boolean;
}
export interface SocialSecurityBenefitsDaysSum {
  daysSum: number;
}
export interface SocialSecurityBenefitsState {
  socialSecurityBenefits: SocialSecurityBenefitsWithUserModel[];
  socialSecurityBenefitsModel: SocialSecurityBenefitsModel[];
  isLoading: boolean;
  userId?: number;
  daysSum?: number;
}
const initialState: SocialSecurityBenefitsState = {
  socialSecurityBenefits: [],
  socialSecurityBenefitsModel: [],
  isLoading: false,
};

const socialSecurityBenefitsSlice = createSlice({
  name: 'social-security-benefits',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addUserSocialSecurityBenefits.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        addUserSocialSecurityBenefits.fulfilled,
        (state, { payload }) => {
          state.isLoading = false;
          if (state.socialSecurityBenefits && payload) {
            state.socialSecurityBenefits = [
              ...state.socialSecurityBenefits,
              payload,
            ].sort((socialSecurityBenefit, payloadsort) => {
              return payloadsort.dateFrom.localeCompare(
                socialSecurityBenefit.dateFrom
              );
            });
          }
        }
      )
      .addCase(addUserSocialSecurityBenefits.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchUserSocialSecurityBenefits.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUserSocialSecurityBenefits.fulfilled, (state, action) => {
        state.isLoading = false;
        state.socialSecurityBenefits = action?.payload ?? [];
      })
      .addCase(fetchUserSocialSecurityBenefits.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchAllUsersSocialSecurityBenefits.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchAllUsersSocialSecurityBenefits.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.socialSecurityBenefits = action?.payload ?? [];
        }
      )
      .addCase(fetchAllUsersSocialSecurityBenefits.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchDaysSumForUserAndYear.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDaysSumForUserAndYear.fulfilled, (state, action) => {
        state.isLoading = false;
        state.daysSum = action?.payload?.daysSum;
      })
      .addCase(fetchDaysSumForUserAndYear.rejected, (state) => {
        state.isLoading = false;
      })
      .addMatcher(
        isFulfilled(
          editUserSocialSecurityBenefits,
          deleteUserSocialSecurityBenefits
        ),
        (state, { payload }) => {
          if (payload) {
            const { id, ...rest } = payload;

            state.socialSecurityBenefits = state.socialSecurityBenefits.map(
              (data) => {
                const newSocialSecurityBenefit = { ...data, ...rest };
                return data.id === id ? newSocialSecurityBenefit : data;
              }
            );
          }
        }
      );
  },
});

export default socialSecurityBenefitsSlice.reducer;

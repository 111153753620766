import { useEffect, useState, FC, useCallback } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import plLocale from '@fullcalendar/core/locales/pl';
import {
  Box,
  Heading,
  useDisclosure,
  List,
  Grid,
  GridItem,
  Alert,
  Text,
} from '@chakra-ui/react';
import { getYear } from 'date-fns/esm';
import {
  correctFromCalendarDate,
  getLastDayOfNextYear,
} from '../../utils/date';
import { useAppDispatch, useAppSelector } from '../../store';
import { fetchHolidayUsersAndSetCurrentHolidays } from '../../redux/holidays/thunks';
import { fetchSpecificYearUsersHolidayPool } from '../../redux/holidayPools/thunks';
import {
  fetchAllUsersSocialSecurityBenefits,
  fetchDaysSumForUserAndYear,
  fetchUserSocialSecurityBenefits,
} from '../../redux/socialSecurityBenefits/thunks';
import {
  selectSpecificYearUserHolidayPool,
  selectSpecificYearUsersHolidayPools,
} from '../../redux/holidayPools/selectors';
import { selectSocialSecurityBenefitsDaysSum } from '../../redux/socialSecurityBenefits/selectors';
import {
  selectCurrentEvents,
  selectCurrentHolidays,
  selectHolidayUsers,
  selectIsHolidaysLoading,
} from '../../redux/holidays/selectors';
import { setCurrentHolidays } from '../../redux/holidays';
import { selectCurrentUserId } from '../../redux/user/selectors';
import { HolidayApplication } from './HolidayApplication';
import { GenerateReport } from './GenerateReport';
import interactionPlugin from '@fullcalendar/interaction';
import { Legend } from './Legend';
import { fetchPublicHolidays } from '../../redux/publicHolidays/thunks';
import { EventContentInterface } from '../../types/calendarEventContent';
import './calendar.scss';
import { CenteredSpinner } from '../../components/common/CenteredSpinner';
import { DeleteHolidayApplication } from './HolidayApplication/DeleteHolidayApplication';
import { displayHolidayPools } from './displayHolidayPoolsDetails';
import { TooltipForPublicHolidays } from './iconsOnEvents/tooltipForPublicHolidays';
import { NationalHolidayApplication } from './PublicHolidaysAdmin/AddNationalHolidayAdmin';
import { CompanyHolidayApplication } from './PublicHolidaysAdmin/AddCompanyHolidayAdmin';
import { acceptHolidayFromCalendarAsAdmin } from '../../redux/holidaysAdmin/thunks';
import { AdminActionIconsOnCalendarEvent } from './iconsOnEvents/AdminActionIconsOnCalendarEvent';
import { ALL_HOLIDAYS } from '../../utils/consts';
import { ScrollableSelect } from '../common/Select/scrollableSelect';
import { getAdditionalHolidayUserPool } from 'src/redux/additionalHoliday/thunks';
import { selectUserAdditionalHolidaysPool } from 'src/redux/additionalHoliday/selectors';

interface Props {
  isForAdmin: boolean;
}

export const IntranetCalendar: FC<Props> = ({ isForAdmin }) => {
  const [[dateStart, dateEnd], setDateRange] = useState<
    [null | Date, null | Date]
  >([null, null]);
  const [clickedNationalAdmin, setClickedNationalAdmin] = useState(false);
  const [clickedCompAdmin, setClickedCompAdmin] = useState(false);
  const [allSelected, setAllSected] = useState(isForAdmin);
  const [hoveredEventId, setHoveredEventId] = useState<string | undefined>();
  const addHolidayDisclosure = useDisclosure();
  const generateReportDisclosure = useDisclosure();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectIsHolidaysLoading);
  const calculateUserAdditionalHolidayPool = useAppSelector(
    selectUserAdditionalHolidaysPool
  );
  const holidays = useAppSelector(selectHolidayUsers);
  const currentHolidays = useAppSelector(selectCurrentHolidays);
  const userId = useAppSelector(selectCurrentUserId);
  const [selectedUserId, setSelectedUserId] = useState<number | undefined>(
    isForAdmin ? ALL_HOLIDAYS : userId
  );
  const [expandCalendarRows, setExpandCalendarRows] = useState(false);

  const specificYearUserHolidayPool = useAppSelector((state) =>
    selectSpecificYearUserHolidayPool(state, selectedUserId)
  );
  const specificYearUsersHolidayPool = useAppSelector(
    selectSpecificYearUsersHolidayPools
  );
  const currentEvents = useAppSelector((state) =>
    selectCurrentEvents(state, isForAdmin, userId)
  );
  const socialSecurityBenefitsDaysSum = useAppSelector(
    selectSocialSecurityBenefitsDaysSum
  );
  const currentYear = getYear(new Date());
  const [holidayIdDelete, setHolidayIdDelete] = useState<number>();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [calendarEventAlertInfo, setCalendarEventAlertInfo] = useState<
    | {
        text: string;
        left: number;
        top: number;
      }
    | undefined
  >();

  useEffect(() => {
    if (userId && !isForAdmin) {
      void dispatch(
        fetchSpecificYearUsersHolidayPool({
          year: currentYear,
          orderingField: 'user__first_name,user__last_name',
        })
      );
      void dispatch(
        fetchDaysSumForUserAndYear({ id: userId, year: currentYear })
      );
      void dispatch(
        fetchUserSocialSecurityBenefits({ id: userId, isCancelled: false })
      );
      void dispatch(
        fetchHolidayUsersAndSetCurrentHolidays(userId, 'first_name,last_name')
      );
    } else if (userId && isForAdmin) {
      void dispatch(
        fetchSpecificYearUsersHolidayPool({
          year: currentYear,
          orderingField: 'user__last_name,user__first_name',
        })
      );
      void dispatch(
        fetchDaysSumForUserAndYear({ id: userId, year: currentYear })
      );
      void dispatch(
        fetchAllUsersSocialSecurityBenefits({ isCancelled: false })
      );
      void dispatch(
        fetchHolidayUsersAndSetCurrentHolidays(
          ALL_HOLIDAYS,
          'last_name,first_name'
        )
      );
    }
  }, [dispatch, userId, currentYear, isForAdmin]);

  useEffect(() => {
    void dispatch(fetchPublicHolidays());
  }, [dispatch]);

  useEffect(() => {
    void dispatch(
      getAdditionalHolidayUserPool({
        year: currentYear,
        user: selectedUserId ? selectedUserId : (userId as number),
      })
    );
  }, [dispatch, currentYear, selectedUserId, userId]);

  const handleClick = (id: number) => {
    setCalendarEventAlertInfo(undefined);
    setHolidayIdDelete(id);
  };
  const handleAccept = async (id: number) => {
    setCalendarEventAlertInfo(undefined);
    await dispatch(acceptHolidayFromCalendarAsAdmin({ id }));
  };
  const renderEventContent = (eventInfo: EventContentInterface) => {
    if (eventInfo.event.title === 'FiInfo') {
      return <TooltipForPublicHolidays eventInfo={eventInfo} />;
    }
    if (eventInfo.event.extendedProps.hasTrashIcon) {
      return (
        <AdminActionIconsOnCalendarEvent
          eventInfo={eventInfo}
          handleDelete={handleClick}
          handleAccept={handleAccept}
          isForAdmin={isForAdmin}
          hoveredEventId={hoveredEventId}
        />
      );
    }
  };

  const handleReturn = useCallback(() => {
    if (addHolidayDisclosure.isOpen && isForAdmin) {
      if (clickedNationalAdmin && !clickedCompAdmin) {
        return (
          <NationalHolidayApplication
            onClose={addHolidayDisclosure.onClose}
            isOpen={addHolidayDisclosure.isOpen}
          />
        );
      } else if (clickedCompAdmin && !clickedNationalAdmin) {
        return (
          <CompanyHolidayApplication
            onClose={addHolidayDisclosure.onClose}
            isOpen={addHolidayDisclosure.isOpen}
            holidays={holidays}
            setCurrentDate={setCurrentDate}
            currentYear={currentYear}
          />
        );
      }
    } else if (addHolidayDisclosure.isOpen && !isForAdmin) {
      return (
        <HolidayApplication
          onClose={addHolidayDisclosure.onClose}
          isOpen={addHolidayDisclosure.isOpen}
          dateFrom={dateStart}
          dateTo={dateEnd}
          year={currentYear}
          setCurrentDate={setCurrentDate}
        />
      );
    } else {
      return null;
    }
  }, [
    addHolidayDisclosure.isOpen,
    addHolidayDisclosure.onClose,
    clickedCompAdmin,
    clickedNationalAdmin,
    currentYear,
    dateEnd,
    dateStart,
    isForAdmin,
    holidays,
  ]);
  const alertShiftRight = 1;
  const alertShiftTop = 6;

  if (isLoading || !currentHolidays) return <CenteredSpinner />;

  const options = [
    { value: 0, label: 'Wszyscy' },
    ...(!holidays.length ? [currentHolidays] : holidays).map(
      ({ id, firstName, lastName }) => ({
        value: id,
        label: `${firstName} ${lastName}`,
      })
    ),
  ];

  return (
    <Grid
      columnGap={8}
      gridTemplateColumns={['1fr', null, null, null, '2.5fr 7.5fr', '2fr 8fr']}
    >
      <GridItem maxWidth="100%" overflowX="hidden" overflowY="visible">
        <Box>
          <Heading fontWeight={300} as="h1" my={4} size="lg">
            PLAN URLOPÓW
          </Heading>
          <Box padding="1px">
            <ScrollableSelect
              options={options}
              onChange={(value) => {
                const numberValue = Number(value?.value);
                if (isNaN(numberValue)) return;

                dispatch(setCurrentHolidays(numberValue));
                setSelectedUserId(numberValue);
                if (numberValue !== ALL_HOLIDAYS) {
                  void dispatch(
                    fetchUserSocialSecurityBenefits({
                      id: numberValue,
                      isCancelled: false,
                    })
                  );
                  setAllSected(false);
                } else if (numberValue === ALL_HOLIDAYS) {
                  void dispatch(
                    fetchAllUsersSocialSecurityBenefits({ isCancelled: false })
                  );
                  setAllSected(true);
                }
              }}
              currentValue={options.find(
                (option) => option.value === currentHolidays.id
              )}
              defaultValue={options.find(
                (option) =>
                  option.value === (isForAdmin ? ALL_HOLIDAYS : userId)
              )}
            />
          </Box>
          <Box position="relative">
            <List
              className="list-with-invisible-scrollbar"
              overflow="auto"
              alignItems="left"
              position="relative"
              mt={25}
              height={['auto', null, null, null, '43.75rem', '56.25rem']}
            >
              {displayHolidayPools(
                allSelected,
                isForAdmin,
                specificYearUsersHolidayPool,
                currentYear,
                userId,
                selectedUserId,
                socialSecurityBenefitsDaysSum,
                specificYearUserHolidayPool,
                calculateUserAdditionalHolidayPool
              )}
            </List>
            <Box className="blur-effect-list" />
          </Box>
        </Box>
      </GridItem>
      <GridItem>
        <Box>
          {handleReturn()}
          {holidayIdDelete != null && (
            <DeleteHolidayApplication
              id={holidayIdDelete}
              isForAdmin={isForAdmin}
              onClose={() => {
                addHolidayDisclosure.onClose();
                setHolidayIdDelete(undefined);
              }}
              dateFrom={dateStart}
              dateTo={dateEnd}
              currentYear={currentYear}
            />
          )}
          {generateReportDisclosure.isOpen && (
            <GenerateReport
              onClose={generateReportDisclosure.onClose}
              isOpen={generateReportDisclosure.isOpen}
              holidays={holidays}
            />
          )}

          {calendarEventAlertInfo ? (
            <Alert
              left={calendarEventAlertInfo.left}
              top={calendarEventAlertInfo.top}
              width="fit-content"
              height="fit-content"
              padding="0"
              textAlign="center"
              position="absolute"
              status="info"
              backgroundColor="dark"
              borderRadius="3px"
              color="light"
              fontSize="size2"
              fontWeight="300"
              zIndex="100"
            >
              <Text padding="5px">{calendarEventAlertInfo.text}</Text>
            </Alert>
          ) : undefined}

          <FullCalendar
            initialView="dayGridMonth"
            plugins={[dayGridPlugin, interactionPlugin]}
            headerToolbar={{
              right: 'prev,next today',
              center: 'title',
              left: '',
            }}
            contentHeight={expandCalendarRows ? 'auto' : undefined}
            selectable
            selectMirror
            select={(e) => {
              setCalendarEventAlertInfo(undefined);
              setDateRange([e.start, correctFromCalendarDate(e.end)]);
              setClickedNationalAdmin(false);
              setClickedCompAdmin(false);

              if (expandCalendarRows) {
                setExpandCalendarRows(false);
              } else if (!expandCalendarRows && !isForAdmin) {
                addHolidayDisclosure.onOpen();
              }
            }}
            moreLinkClick={() => {
              setExpandCalendarRows(true);
              return 'timeGridDay';
            }}
            dayMaxEvents={!expandCalendarRows}
            moreLinkText=""
            firstDay={1}
            locales={[plLocale]}
            locale="pl"
            validRange={{
              end: getLastDayOfNextYear(),
            }}
            initialDate={currentDate}
            events={currentEvents}
            aspectRatio={1.2}
            eventContent={renderEventContent}
            eventClick={(e) => {
              if (e.event.start && e.event.end) {
                setDateRange([e.event.start, e.event.end]);
              }
              if (calendarEventAlertInfo || !e.event.extendedProps.alertInfo) {
                setCalendarEventAlertInfo(undefined);
              } else {
                setCalendarEventAlertInfo({
                  text: e.event.extendedProps.alertInfo as string,
                  left: e.jsEvent.pageX - e.jsEvent.offsetX - alertShiftRight,
                  top:
                    e.jsEvent.pageY -
                    e.jsEvent.offsetY -
                    (e.el.clientHeight + alertShiftTop),
                });
              }
            }}
            eventMouseEnter={function (mouseEnterInfo) {
              setHoveredEventId(mouseEnterInfo.event.id);
            }}
            eventMouseLeave={function () {
              setHoveredEventId(undefined);
            }}
          />
          <Legend
            onOpenAddHoliday={() => {
              setDateRange([null, null]);
              addHolidayDisclosure.onOpen();
            }}
            onOpenAddNationalAdmin={() => {
              setDateRange([null, null]);
              setClickedNationalAdmin(true);
              setClickedCompAdmin(false);
              addHolidayDisclosure.onOpen();
            }}
            onOpenAddCompAdmin={() => {
              setDateRange([null, null]);
              setClickedNationalAdmin(false);
              setClickedCompAdmin(true);
              addHolidayDisclosure.onOpen();
            }}
            onOpenGenerateReport={() => {
              setDateRange([null, null]);
              generateReportDisclosure.onOpen();
            }}
            isForAdmin={isForAdmin}
          />
        </Box>
      </GridItem>
    </Grid>
  );
};

import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { getYear } from 'date-fns/esm';
import { selectIsAddHolidayPoolLoading } from '../../../../../redux/holidayPools/selectors';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { FormWrapper } from '../../../../../components/form/FormWrapper';
import { CenteredSpinner } from '../../../../../components/common/CenteredSpinner';
import { selectAdditionalHolidaysTypesAsOption } from 'src/redux/additionalHoliday/selectors';
import { generateYearOptions } from '../holidayPools/utils';
import { AdditionalHolidayPoolForm } from './AdditionalHolidayPoolForm';
import { AddAdditionalUserHolidayPoolForm } from 'types/additionalHoliday';
import {
  addAdditionalHolidayPool,
  getAdditionalHolidayUserPool,
} from 'src/redux/additionalHoliday/thunks';

interface Props {
  userId: number;
}
export const AddAdditionalHolidayPool: FC<Props> = ({ userId }) => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectIsAddHolidayPoolLoading);
  const typeOptions = useAppSelector((state) =>
    selectAdditionalHolidaysTypesAsOption(state)
  );
  const currentYear = getYear(new Date());
  const yearOptions = generateYearOptions(currentYear);

  const methods = useForm<AddAdditionalUserHolidayPoolForm>({
    defaultValues: {
      user: userId,
    },
  });

  const handleSubmit = async (data: AddAdditionalUserHolidayPoolForm) => {
    methods.reset({
      user: userId,
      year: undefined,
      holiday: undefined,
    });
    try {
      await dispatch(addAdditionalHolidayPool(data)).unwrap();
      await dispatch(getAdditionalHolidayUserPool({ user: userId }));
    } catch (error) {
      console.error(error);
    }
  };

  return isLoading ? (
    <CenteredSpinner />
  ) : (
    <FormWrapper methods={methods} onSubmit={handleSubmit}>
      <AdditionalHolidayPoolForm
        yearOptions={yearOptions}
        typeOptions={typeOptions}
      />
    </FormWrapper>
  );
};

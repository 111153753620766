import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  SocialSecurityBenefitsAddModel,
  SocialSecurityBenefitsEditModel,
  SocialSecurityBenefitsModel,
  SocialSecurityBenefitsWithUserModel,
  SocialSecurityBenefitsDaysSum,
} from '.';
import client from '../../api/axiosConfig';
import { DeleteSocialSecurityBenefit } from '.';
import { ThunkError } from '../../types/thunk';
import { formatToBackendDate } from '../../utils/date';
import { handleError } from '../../utils/handleError';
import { displaySuccessToast } from '../../utils/toasts';

export const fetchUserSocialSecurityBenefits = createAsyncThunk<
  SocialSecurityBenefitsWithUserModel[] | undefined,
  { id: number; isCancelled: boolean | undefined }
>(
  'social-security-benefits/fetch',
  async ({ id, isCancelled }, { rejectWithValue }) => {
    try {
      const condition =
        isCancelled === undefined
          ? ''
          : `&is_cancelled=${isCancelled.toString()}`;
      const response = await client.get<{
        results: SocialSecurityBenefitsWithUserModel[];
      }>(`people/social-security-benefits/?user=${id}${condition}`);

      return response.data.results;
    } catch (error) {
      handleError(error as ThunkError);
      return rejectWithValue((error as ThunkError)?.response?.data);
    }
  }
);

export const fetchAllUsersSocialSecurityBenefits = createAsyncThunk<
  SocialSecurityBenefitsWithUserModel[] | undefined,
  { isCancelled: boolean | undefined }
>(
  'social-security-benefits/fetch-all',
  async ({ isCancelled }, { rejectWithValue }) => {
    try {
      const condition =
        isCancelled === undefined
          ? ''
          : `?is_cancelled=${isCancelled.toString()}`;
      const response = await client.get<{
        results: SocialSecurityBenefitsWithUserModel[];
      }>(`people/social-security-benefits/${condition}`);

      return response.data.results;
    } catch (error) {
      handleError(error as ThunkError);
      return rejectWithValue((error as ThunkError)?.response?.data);
    }
  }
);
export const fetchDaysSumForUserAndYear = createAsyncThunk<
  SocialSecurityBenefitsDaysSum | undefined,
  { id: number; year: number }
>(
  'social-security-benefits/fetchDaysSum',
  async ({ id, year }, { rejectWithValue }) => {
    try {
      const response = await client.get<{
        results: SocialSecurityBenefitsDaysSum;
      }>(
        `people/social-security-benefits/get-days-sum/?user=${id}&year=${year}`
      );

      return response.data.results;
    } catch (error) {
      handleError(error as ThunkError);
      return rejectWithValue((error as ThunkError)?.response?.data);
    }
  }
);
export const addUserSocialSecurityBenefits = createAsyncThunk<
  SocialSecurityBenefitsWithUserModel | undefined,
  SocialSecurityBenefitsAddModel
>(
  'social-security-benefits/create',
  async (
    {
      user,
      dateFrom,
      dateTo,
      type,
      additionalHolidayTypeId,
      additionalHolidayId,
      addInfo,
    },
    { rejectWithValue }
  ) => {
    try {
      if (!dateFrom || !dateTo) return; // Required in validation

      const response = await client.post<{
        results: SocialSecurityBenefitsWithUserModel;
      }>('people/social-security-benefits/', {
        user,
        dateFrom: formatToBackendDate(dateFrom),
        dateTo: formatToBackendDate(dateTo),
        type,
        additionalHolidayTypeId,
        additionalHolidayId,
        addInfo: addInfo === '' ? null : addInfo,
      });
      if (response.data.results)
        displaySuccessToast({ title: 'Dodano świadczenie ZUS' });
      // The value we return becomes the `fulfilled` action payload
      return response.data.results;
    } catch (error) {
      handleError(error as ThunkError);
      return rejectWithValue((error as ThunkError)?.response?.data);
    }
  }
);

export const deleteUserSocialSecurityBenefits = createAsyncThunk<
  DeleteSocialSecurityBenefit | undefined,
  DeleteSocialSecurityBenefit
>('social-security-benefits/delete', async ({ id }, { rejectWithValue }) => {
  try {
    const response = await client.patch<{
      results: DeleteSocialSecurityBenefit;
    }>(`people/social-security-benefits/${id}/`, { isCancelled: true });

    if (response.data.results)
      displaySuccessToast({ title: 'Usunięto pomyślnie' });
    return response.data.results;
  } catch (error) {
    handleError(error as ThunkError);
    return rejectWithValue((error as ThunkError)?.response?.data);
  }
});

export const editUserSocialSecurityBenefits = createAsyncThunk<
  SocialSecurityBenefitsModel | undefined,
  SocialSecurityBenefitsEditModel
>(
  'social-security-benefits/edit',
  async (
    { id, dateTo, dateFrom, type, addInfo, user },
    { rejectWithValue }
  ) => {
    const formattedDateFrom = dateFrom
      ? formatToBackendDate(dateFrom)
      : undefined;
    const formattedDateTo = dateTo ? formatToBackendDate(dateTo) : undefined;

    try {
      const response = await client.patch<{
        results: SocialSecurityBenefitsModel;
      }>(`people/social-security-benefits/${id}/`, {
        dateFrom: formattedDateFrom,
        dateTo: formattedDateTo,
        type,
        addInfo,
        user,
      });
      if (response.data.results)
        displaySuccessToast({ title: 'Edytowano pomyślnie' });
      return response.data.results;
    } catch (error) {
      handleError(error as ThunkError);
      return rejectWithValue((error as ThunkError)?.response?.data);
    }
  }
);

import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { FC } from 'react';
import { useAppDispatch } from '../../../../../store';
import {
  deleteAdditionalHolidayPool,
  getAdditionalHolidayUserPool,
} from 'src/redux/additionalHoliday/thunks';

interface Props {
  onClose: () => void;
  userId: number;
  additionalHolidayPoolId: number | undefined;
}

export const DeleteAdditionalHolidayPool: FC<Props> = ({
  additionalHolidayPoolId,
  onClose,
  userId,
}) => {
  const dispatch = useAppDispatch();
  const onSubmit = async () => {
    if (!additionalHolidayPoolId) return;
    try {
      await dispatch(
        deleteAdditionalHolidayPool(additionalHolidayPoolId)
      ).unwrap();
      await dispatch(getAdditionalHolidayUserPool({ user: userId }));
      onClose();
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Modal isOpen onClose={onClose} size="xl" autoFocus={false}>
      <ModalOverlay backdropFilter="blur(30px)" />
      <ModalContent px={12} py={28} maxW="680px">
        <ModalBody justifyContent="center">
          <Text textAlign="center" fontSize="size13" pb={8}>
            CZY NA PEWNO CHCESZ USUNĄĆ DODATKOWĄ PULĘ URLOPOWĄ?
          </Text>
        </ModalBody>
        <ModalFooter justifyContent="center" gap={16}>
          <Button variant="main" onClick={onSubmit}>
            TAK
          </Button>
          <Button variant="main" onClick={onClose}>
            NIE
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../../../../../store';
import { FormWrapper } from '../../../../../components/form/FormWrapper';
import { FormSocialSecurityBenefits } from './FormSocialSecurityBenefits';
import { addUserSocialSecurityBenefits } from '../../../../../redux/socialSecurityBenefits/thunks';
import { SocialSecurityBenefitsAddModel } from '../../../../../redux/socialSecurityBenefits';
import { fetchUserHolidays } from '../../../../../redux/holidaysAdmin/thunks';
import { selectIsLoading } from '../../../../../redux/socialSecurityBenefits/selectors';
import { useAppSelector } from '../../../../../store';
import { CenteredSpinner } from '../../../../../components/common/CenteredSpinner';

interface Props {
  userId: number;
}
export const AddSocialSecurityBenefits: FC<Props> = ({ userId }) => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectIsLoading);

  const methods = useForm<SocialSecurityBenefitsAddModel>({
    defaultValues: {
      user: userId,
    },
  });

  const handleSubmit = async (data: SocialSecurityBenefitsAddModel) => {
    try {
      await dispatch(addUserSocialSecurityBenefits(data)).unwrap();
      methods.reset({
        user: userId,
        type: '',
        typeTemp: '',
        dateTo: null,
        dateFrom: null,
        addInfo: null,
        additionalHolidayId: undefined,
        additionalHolidayTypeId: undefined,
      });
    } catch (e) {
      console.error(e);
    }
    void dispatch(fetchUserHolidays(userId));
  };

  return isLoading ? (
    <CenteredSpinner />
  ) : (
    <FormWrapper methods={methods} onSubmit={handleSubmit}>
      <FormSocialSecurityBenefits />
    </FormWrapper>
  );
};

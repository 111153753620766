import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { FormWrapper } from '../../../../../components/form/FormWrapper';
import { CenteredSpinner } from '../../../../../components/common/CenteredSpinner';

import { addAdditionalHolidayType } from 'src/redux/additionalHoliday/thunks';
import { AdditionalHolidayTypeFormProps } from 'types/forms';
import { AdditionalHolidayTypeForm } from './AdditionalHolidayTypeForm';
import { selectAreAdditionalHolidaysLoading } from 'src/redux/additionalHoliday/selectors';

export const AddAdditionalHolidayTypeForm: FC = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectAreAdditionalHolidaysLoading);

  const methods = useForm<AdditionalHolidayTypeFormProps>();

  const handleSubmit = async (data: AdditionalHolidayTypeFormProps) => {
    methods.reset({});

    try {
      await dispatch(addAdditionalHolidayType(data)).unwrap();
    } catch (error) {
      console.error(error);
    }
  };

  return isLoading ? (
    <CenteredSpinner />
  ) : (
    <FormWrapper methods={methods} onSubmit={handleSubmit}>
      <AdditionalHolidayTypeForm />
    </FormWrapper>
  );
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import client from '../../api/axiosConfig';
import { ThunkError } from '../../types/thunk';
import { handleError } from '../../utils/handleError';
import {
  AddAdditionalUserHolidayPoolForm,
  AdditionalHoliday,
  AdditionalUserHolidayPool,
  AdditionalUserHolidayPoolRequest,
} from 'types/additionalHoliday';
import { AdditionalHolidayTypeFormProps } from 'types/forms';
import { displaySuccessToast } from 'utils/toasts';

export const addAdditionalHolidayType = createAsyncThunk<
  AdditionalHoliday | undefined,
  AdditionalHolidayTypeFormProps
>(
  'additionalHoliday/createAdditionalHolidayType',
  async (inputData, { rejectWithValue }) => {
    try {
      const response = await client.post<{
        results: AdditionalHoliday;
      }>(`people/additional-holiday/`, inputData);
      if (response.data.results)
        displaySuccessToast({ title: 'Dodano pomyślnie' });
      return response.data.results;
    } catch (error) {
      handleError(error as ThunkError);
      return rejectWithValue((error as ThunkError)?.response?.data);
    }
  }
);

export const fetchAdditionalHolidayTypes = createAsyncThunk<
  AdditionalHoliday[]
>(
  'additional-holiday/fetchAdditionalHolidayTypes',
  async (_, { rejectWithValue }) => {
    try {
      const response = await client.get<{ results: AdditionalHoliday[] }>(
        `people/additional-holiday/`
      );
      return response.data.results;
    } catch (error) {
      handleError(error as ThunkError);
      return rejectWithValue((error as ThunkError)?.response?.data);
    }
  }
);

export const addAdditionalHolidayPool = createAsyncThunk<
  AdditionalHoliday | undefined,
  AddAdditionalUserHolidayPoolForm
>(
  'additionalHoliday/addAdditionalUserHoliday',
  async (inputData, { rejectWithValue }) => {
    try {
      const response = await client.post<{
        results: AdditionalHoliday;
      }>(`people/additional-holiday-pool/`, inputData);
      if (response.data.results)
        displaySuccessToast({ title: 'Dodano pomyślnie' });
      return response.data.results;
    } catch (error) {
      handleError(error as ThunkError);
      return rejectWithValue((error as ThunkError)?.response?.data);
    }
  }
);

export const getAdditionalHolidayUserPool = createAsyncThunk<
  AdditionalUserHolidayPool[] | undefined,
  AdditionalUserHolidayPoolRequest
>(
  'additionalHoliday/getAdditionalHolidayUserPool',
  async (inputData, { rejectWithValue }) => {
    const params = inputData.year
      ? `?year=${inputData.year}&user=${inputData.user}`
      : `?user=${inputData.user}`;
    try {
      const response = await client.get<{
        results: AdditionalUserHolidayPool[];
      }>(`people/calculate-additional-days-for-user/${params}`);
      return response.data.results;
    } catch (error) {
      handleError(error as ThunkError);
      return rejectWithValue((error as ThunkError)?.response?.data);
    }
  }
);

export const deleteAdditionalHolidayPool = createAsyncThunk<
  AdditionalHoliday[],
  number
>(
  'additionalHoliday/deleteAdditionalUserHoliday',
  async (inputData, { rejectWithValue }) => {
    try {
      const response = await client.delete<{
        results: AdditionalHoliday[];
      }>(`people/additional-holiday-pool/${inputData}/`);
      displaySuccessToast({ title: 'Usunięto pomyślnie' });
      return response.data.results;
    } catch (error) {
      handleError(error as ThunkError);
      return rejectWithValue((error as ThunkError)?.response?.data);
    }
  }
);

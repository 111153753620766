import { UserHolidayPool } from '../../../redux/holidayPools';
import { mapHolidayPoolsDetailsForEmployee } from './mapHolidayPoolsDetailsForEmployee';
import { mapHolidayPoolsDetailsForAdmin } from './mapHolidayPoolsDetailsForAdmin';
import { formatHolidayPoolDetailsForOneUser } from './formatHolidayPoolDetailsForOneUser';
import { formatHolidayPoolGeneralInfoForOneUser } from './formatHolidayPoolGeneralInfoForOneUser';
import { AdditionalUserHolidayPool } from 'types/additionalHoliday';

export function displayHolidayPools(
  allSelected: boolean,
  isForAdmin: boolean,
  specificYearUsersHolidayPools: UserHolidayPool[],
  currentYear: number,
  currentUserId?: number,
  selectedUserId?: number,
  socialSecurityBenefitsDaysSum?: number,
  specificYearUserHolidayPool?: UserHolidayPool,
  calculateUserAdditionalHolidayPool?: AdditionalUserHolidayPool[]
) {
  console.log(calculateUserAdditionalHolidayPool);
  if (allSelected && !isForAdmin) {
    return mapHolidayPoolsDetailsForEmployee(specificYearUsersHolidayPools);
  } else if (allSelected && isForAdmin) {
    return mapHolidayPoolsDetailsForAdmin(
      currentYear,
      specificYearUsersHolidayPools
    );
  } else if (!allSelected && (isForAdmin || currentUserId == selectedUserId)) {
    return formatHolidayPoolDetailsForOneUser(
      currentYear,
      socialSecurityBenefitsDaysSum,
      specificYearUserHolidayPool,
      calculateUserAdditionalHolidayPool
    );
  } else if (!allSelected && currentUserId != selectedUserId && !isForAdmin) {
    return formatHolidayPoolGeneralInfoForOneUser(specificYearUserHolidayPool);
  }
}

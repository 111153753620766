import { FC, useEffect, useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  HStack,
  Heading,
  VStack,
  Box,
  Icon,
  Grid,
  GridItem,
  Tooltip,
  Flex,
  UnorderedList,
  ListItem,
  Text,
} from '@chakra-ui/react';
import { getYear } from 'date-fns/esm';
import { ActionMeta, MultiValue } from 'react-select';
import { MdFilterAlt } from 'react-icons/md';
import { FiInfo } from 'react-icons/fi';
import { useAppDispatch, useAppSelector } from '../../../store';
import { CenteredSpinner } from '../../../components/common/CenteredSpinner';
import { StatsYearButtons } from '../../../components/statistics/statsYearButtons';
import {
  fetchStatsForTable,
  fetchUsedDaysStatsForChart,
  fetchHolidayTypesStatsForChart,
} from '../../../redux/statistics/thunks';
import {
  selectIsLoadingStatsForTable,
  selectIsLoadingDaysUsedStatsForChart,
  selectIsLoadingHolidayTypesStatsForChart,
  selectUserStatsTable,
  selectUserDaysUsedStatsChart,
  selectUserHolidayTypesStatsChart,
} from '../../../redux/statistics/selectors';
import { UserStatsChart } from '../../../components/statistics/userStatsChart';
import { UserStatsTable } from '../../../components/statistics/userStatsTable';
import { fetchUsers } from '../../../redux/users/thunks';
import { selectUsers } from '../../../redux/users/selectors';
import { SelectOption } from '../../../types/SelectOptions';
import {
  MULTISELECT_NONE_SELECTED_LABEL,
  MULTISELECT_ALL_SELECTED_LABEL,
} from '../../../utils/consts';
import { MultiSelect } from '../../../components/form/inputs/MulitSelectInput';
import { FormWrapper } from '../../../components/form/FormWrapper';
import { MONTH_LABELS } from '../../../utils/consts';

export const Statistics: FC = () => {
  const dispatch = useAppDispatch();
  const isLoadingStatsForTable = useAppSelector(selectIsLoadingStatsForTable);
  const isLoadingDaysUsedStatsForChart = useAppSelector(
    selectIsLoadingDaysUsedStatsForChart
  );
  const isLoadingHolidayTypesStatsForChart = useAppSelector(
    selectIsLoadingHolidayTypesStatsForChart
  );
  const userStatsTable = useAppSelector(selectUserStatsTable);
  const userDaysUsedStatsChart = useAppSelector(selectUserDaysUsedStatsChart);
  const userHolidayTypesStatsChart = useAppSelector(
    selectUserHolidayTypesStatsChart
  );
  const userList = useAppSelector(selectUsers);
  const [selectedTableYear, setSelectedStatsYear] = useState(
    getYear(new Date())
  );
  const [selectedChartYear, setSelectedChartYear] = useState(
    getYear(new Date())
  );

  const [selected, setSelected] = useState<readonly SelectOption[]>([]);
  const [allClicked, setAllClicked] = useState(false);
  const [multiSelectLabel, setMultiSelectLabel] = useState(
    MULTISELECT_NONE_SELECTED_LABEL
  );
  const methods = useForm<string[]>();

  const allOptions = useMemo(
    () => [
      {
        label: MULTISELECT_ALL_SELECTED_LABEL,
        value: MULTISELECT_ALL_SELECTED_LABEL,
      },
      ...userList.map(({ firstName, lastName, id }) => ({
        label: `${firstName} ${lastName}`,
        value: id.toString(),
      })),
    ],
    [userList]
  );

  useEffect(() => {
    try {
      void dispatch(
        fetchUsers({
          orderingFieldName: 'last_name',
          desc: false,
        })
      );
    } catch (error) {
      console.error(error);
    }
  }, [dispatch]);

  useEffect(() => {
    try {
      void dispatch(fetchStatsForTable(selectedTableYear)).unwrap();
    } catch (error) {
      console.error(error);
    }
  }, [dispatch, selectedTableYear]);

  useEffect(() => {
    try {
      void dispatch(fetchHolidayTypesStatsForChart(selectedChartYear)).unwrap();
      void dispatch(
        fetchUsedDaysStatsForChart({ year: selectedChartYear })
      ).unwrap();
    } catch (error) {
      console.error(error);
    }
  }, [dispatch, selectedChartYear]);

  const handleChange = (
    e: MultiValue<SelectOption>,
    value: ActionMeta<SelectOption>
  ) => {
    const { action, option } = value;
    if (option?.label !== MULTISELECT_ALL_SELECTED_LABEL) {
      const selectedOptionsWithoutAll = e.filter(
        ({ value }) => value !== MULTISELECT_ALL_SELECTED_LABEL
      );

      setSelected(e);
      setMultiSelectLabel(
        selectedOptionsWithoutAll.length > 0
          ? `wybrano: ${selectedOptionsWithoutAll.length}`
          : MULTISELECT_NONE_SELECTED_LABEL
      );
      setAllClicked(false);
    } else if (action === 'select-option') {
      setSelected(allOptions);
      setAllClicked(true);
      setMultiSelectLabel(MULTISELECT_ALL_SELECTED_LABEL.toLowerCase());
    } else if (action === 'deselect-option') {
      setSelected([]);
      setAllClicked(true);
      setMultiSelectLabel(MULTISELECT_NONE_SELECTED_LABEL);
    }
  };

  const submitHandler = async (
    data: string[],
    usersFromMultiSelect?: readonly SelectOption[]
  ) => {
    const selectedUsers = usersFromMultiSelect
      ?.filter(({ value }) => value !== MULTISELECT_ALL_SELECTED_LABEL)
      .map((sel) => Number(sel.value));

    try {
      await dispatch(
        fetchUsedDaysStatsForChart({
          year: selectedTableYear,
          userId: selectedUsers,
        })
      ).unwrap();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <VStack width="100%" padding="2.75rem">
      <Heading variant="main" paddingBottom="2.75rem" alignSelf="flex-start">
        PODSUMOWANIE WSZYSTKICH URLOPÓW
      </Heading>
      <Grid width="100%" gridTemplateColumns="1fr 1fr 1fr">
        <GridItem gridColumn={2}>
          <StatsYearButtons
            currentStatsYear={selectedChartYear}
            setCurrentStatsYear={setSelectedChartYear}
          />
        </GridItem>
      </Grid>
      {isLoadingHolidayTypesStatsForChart ? (
        <Box
          width="100%"
          minHeight="500px"
          maxHeight="750px"
          paddingBottom="2.75rem"
          marginTop="3rem"
        >
          <CenteredSpinner />
        </Box>
      ) : (
        <Box
          width="100%"
          minHeight="500px"
          maxHeight="750px"
          paddingBottom="2.75rem"
          marginTop="3rem"
        >
          <UserStatsChart
            horizontalAxisLabels={MONTH_LABELS}
            holidayTypesDataForChart={userHolidayTypesStatsChart}
            stepSizeY={1}
            labelY="Liczba wziętych urlopów"
            labelX="Miesiąc"
            title="Liczba złożonych wniosków urlopowych w ciągu roku"
          />
        </Box>
      )}
      <Grid width="100%" gridTemplateColumns="1fr 2fr">
        <GridItem
          width={['100%', null, null, null, '75%']}
          paddingBottom={['2.75rem', null, null, null, '0']}
          colSpan={[3, null, null, null, 1]}
        >
          <FormWrapper
            onSubmit={(data: string[]) => submitHandler(data, selected)}
            methods={methods}
          >
            <HStack>
              <Icon
                color="active"
                mb="1rem"
                w="2rem"
                h="2rem"
                as={MdFilterAlt}
              />
              <MultiSelect
                options={allOptions}
                name="users"
                propsValue={selected}
                label={multiSelectLabel}
                isAll={allClicked}
                isStandalone
                onChange={handleChange}
                onMenuClose={methods.handleSubmit((data: string[]) =>
                  submitHandler(data, selected)
                )}
              />
            </HStack>
          </FormWrapper>
        </GridItem>
        <GridItem gridColumn={3}>
          <Tooltip
            label={
              <VStack fontSize="size6" padding="10px" alignItems="flex-start">
                <Text>Urlop w ramach puli:</Text>
                <UnorderedList listStylePosition="inside">
                  <ListItem>wypoczynkowy</ListItem>
                  <ListItem>na żądanie</ListItem>
                  <ListItem>firmowy</ListItem>
                </UnorderedList>
                <Text>Urlop poza pulą:</Text>
                <UnorderedList listStylePosition="inside">
                  <ListItem>bezpłatny</ListItem>
                  <ListItem>okolicznościowy</ListItem>
                  <ListItem>opieka nad dzieckiem</ListItem>
                  <ListItem>za święto</ListItem>
                  <ListItem>za delegację</ListItem>
                </UnorderedList>
                <Text>Pozostałe świadczenia:</Text>
                <UnorderedList listStylePosition="inside">
                  <ListItem>macierzyński</ListItem>
                  <ListItem>ojcowski</ListItem>
                  <ListItem>rodzicielski</ListItem>
                  <ListItem>wychowawczy</ListItem>
                </UnorderedList>
              </VStack>
            }
            placement="top-end"
            fontSize="size6"
          >
            <Flex as="span" alignItems="center" justifySelf="end">
              <Icon
                color="active"
                w="2rem"
                h="2rem"
                display="block"
                as={FiInfo}
              />
            </Flex>
          </Tooltip>
        </GridItem>
      </Grid>
      {isLoadingDaysUsedStatsForChart ? (
        <Box
          width="100%"
          minHeight="500px"
          maxHeight="750px"
          paddingBottom="2.75rem"
        >
          <CenteredSpinner />
        </Box>
      ) : (
        <Box
          width="100%"
          minHeight="500px"
          maxHeight="750px"
          paddingBottom="2.75rem"
        >
          <UserStatsChart
            title={`Rozkład długości urlopów w ciągu roku dla ${
              selected.length === 0
                ? 'wszystkich pracowników'
                : selected.length > 1
                ? 'wybranych pracowników'
                : selected[0].label
            }`}
            labelY="Liczba dni"
            labelX="Miesiąc"
            horizontalAxisLabels={MONTH_LABELS}
            daysUsedDataForChart={userDaysUsedStatsChart}
          />
        </Box>
      )}
      <Heading variant="main" alignSelf="flex-start" paddingBottom="2.75rem">
        STATYSTYKI INDYWIDUALNE
      </Heading>

      <StatsYearButtons
        currentStatsYear={selectedTableYear}
        setCurrentStatsYear={setSelectedStatsYear}
      />

      {isLoadingStatsForTable ? (
        <CenteredSpinner />
      ) : (
        <UserStatsTable
          columnSpacing={['1px', null, null, '4px']}
          backgroundColors={['stripedTableColumnBackground', 'light']}
          userStatsData={userStatsTable}
        />
      )}
    </VStack>
  );
};

import { RootState } from 'src/store';

export const selectAdditionalHolidays = (state: RootState) =>
  state.additionalHoliday.additionalHolidays;

export const selectAreAdditionalHolidaysLoading = (state: RootState) =>
  state.additionalHoliday.isLoading;

export const selectUserAdditionalHolidaysPool = (state: RootState) =>
  state.additionalHoliday.userAdditionalHolidaysPool;

export const selectAdditionalHolidaysTypesAsOption = (state: RootState) => {
  return state.additionalHoliday.additionalHolidays.map(({ type, id }) => ({
    label: type,
    value: `${id}`,
  }));
};

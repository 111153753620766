import { createSlice } from '@reduxjs/toolkit';
import { HolidayWithUserModel } from '../holidays';
import { addPublicHolidayAdmin, fetchPublicHolidays } from './thunks';
import { SelectOption } from '../../types/SelectOptions';

export enum PublicHolidayType {
  Company = 'company',
  NationalHoliday = 'national_holiday',
  HolidayComp = 'holiday_comp',
}

export interface PublicHolidayModel {
  id: number;
  dateFrom: Date;
  dateTo: Date;
  type: PublicHolidayType;
  additionalInfo: string;
  users: number[];
}

export interface AddPublicHolidayModelReturn {
  createdPublicHoliday: PublicHolidayModel;
  createdHolidays?: HolidayWithUserModel[];
}

export interface AddPublicHolidayModel {
  dateTo: Date | null;
  dateFrom: Date | null;
  type: PublicHolidayType;
  additionalInfo: string;
  users: number[];
}

export type AddPublicHolidayForm = Omit<AddPublicHolidayModel, 'users'> & {
  users: readonly SelectOption[];
};

export interface PublicHolidaysState {
  publicHolidaySet: PublicHolidayModel[];
  isLoading: boolean;
}

const initialState: PublicHolidaysState = {
  publicHolidaySet: [],
  isLoading: true,
};

const publicHolidaySlice = createSlice({
  name: 'publicHolidays',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPublicHolidays.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPublicHolidays.fulfilled, (state, action) => {
        state.isLoading = false;

        if (action.payload != null) {
          state.publicHolidaySet = action.payload.map((holliday) => {
            return {
              ...holliday,
              dateFrom: new Date(holliday.dateFrom),
              dateTo: new Date(holliday.dateTo),
            };
          });
        }
      })
      .addCase(fetchPublicHolidays.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(addPublicHolidayAdmin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addPublicHolidayAdmin.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        if (state.publicHolidaySet && payload) {
          state.publicHolidaySet = [
            ...state.publicHolidaySet,
            payload.createdPublicHoliday,
          ];
        }
      })
      .addCase(addPublicHolidayAdmin.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default publicHolidaySlice.reducer;

import { Box, Stack, Text, Tooltip } from '@chakra-ui/react';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { AdditionalUserHolidayPool } from 'types/additionalHoliday';

export function formatAdditionalHolidaysPoolDetails(
  additionalHolidayPool?: AdditionalUserHolidayPool[]
) {
  return (
    <Stack>
      {additionalHolidayPool?.map((holidayPool) => (
        <Box key={holidayPool.type}>
          <Box display={'flex'}>
            <Text mb={'0.5rem'}>{holidayPool.type.toUpperCase()}: </Text>
            <Tooltip label={holidayPool.additionalInfo}>
              <span>
                <IoMdInformationCircleOutline
                  style={{ marginLeft: '0.25rem', marginTop: '0.2rem' }}
                />
              </span>
            </Tooltip>
          </Box>
          <Text mb={'0.5rem'} marginLeft={'1rem'}>
            DO WYKORZYSTANIA: {holidayPool.leftDays}
          </Text>
          <Text mb={'0.5rem'} marginLeft={'1rem'}>
            WYKORZYSTANE: {holidayPool.daysUsed}{' '}
          </Text>
        </Box>
      ))}
    </Stack>
  );
}

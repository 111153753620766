import { SocialSecurityBenefitsType } from 'src/redux/socialSecurityBenefits';
import { SelectOption } from './SelectOptions';

export const options: SelectOption[] = [
  { label: 'L4', value: SocialSecurityBenefitsType.l4 },
  { label: 'Opieka nad dzieckiem', value: SocialSecurityBenefitsType.care },
  { label: 'Macierzyński', value: SocialSecurityBenefitsType.maternal },
  { label: 'Ojcowski', value: SocialSecurityBenefitsType.paternal },
  { label: 'Rodzicielski', value: SocialSecurityBenefitsType.paternal },
  { label: 'Wychowawczy', value: SocialSecurityBenefitsType.child_care },
  {
    label: 'Zasiłek rehabilitacyjny',
    value: SocialSecurityBenefitsType.rehabilitation_allowance,
  },
];

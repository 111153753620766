export {};

declare global {
  interface String {
    /**
     * Function that insert in to string values provided
     * in 'args' object. and replace patern {\<property namee in the argsobject\>} eg.\
     * `"Ala ma {0} koty i {1} psy".format({"0": 33, "1": 2);` \
     * resoult:\
     * `"Ala ma 33 koty i 2 psy"`
     * @param args Dictionary value's that will be replaced in the oryginal string.
     * @returns new formated string.
     */
    format(args: { [key: string]: string } | RegExpExecArray): string;
  }
}

if (!String.prototype.format) {
  String.prototype.format = function (args: { [key: string]: string }) {
    if (this !== undefined && typeof this === 'string') {
      return this.replace(/{(\d+)}/g, (match: string, number: string) => {
        return typeof args[number] != 'undefined' ? args[number] : match;
      });
    } else {
      return this.toString();
    }
  };
}

import { SocialSecurityBenefitsType } from '../redux/socialSecurityBenefits';

export const ALL_HOLIDAYS = 0;
export const MULTISELECT_NONE_SELECTED_LABEL = 'pracownicy';
export const MULTISELECT_ALL_SELECTED_LABEL = 'Wszyscy';
export const MONTH_LABELS = [
  'STY',
  'LUT',
  'MAR',
  'KWI',
  'MAJ',
  'CZE',
  'LIP',
  'SIE',
  'WRZ',
  'PAŹ',
  'LIS',
  'GRU',
];
export const FULL_MONTH_LABELS = [
  'Styczeń',
  'Luty',
  'Marzec',
  'Kwiecień',
  'Maj',
  'Czerwiec',
  'Lipiec',
  'Sierpień',
  'Wrzesień',
  'Październik',
  'Listopad',
  'Grudzień',
];
export const HOLIDAY_ACCEPTED_MESSAGE = 'Wniosek urlopowy został zaakceptowany';

export type FULL_WEEK_DAYS_NAMES =
  | 'poniedziałek'
  | 'wtorek'
  | 'środa'
  | 'czwartek'
  | 'piątek'
  | 'sobota'
  | 'niedziela';

export const SHORT_WEEK_DAYS_NAMES = {
  poniedziałek: 'pn',
  wtorek: 'wt',
  środa: 'śr',
  czwartek: 'cz',
  piątek: 'pt',
  sobota: 'sb',
  niedziela: 'nd',
};

export const SOCIAL_SECURITY_BENEFIT_NAMES = {
  [SocialSecurityBenefitsType.l4]: 'l4',
  [SocialSecurityBenefitsType.care]: 'opieka nad dzieckiem',
  [SocialSecurityBenefitsType.maternal]: 'macierzyński',
  [SocialSecurityBenefitsType.paternal]: 'ojcowski',
  [SocialSecurityBenefitsType.parental]: 'rodzicielski',
  [SocialSecurityBenefitsType.child_care]: 'wychowawczy',
  [SocialSecurityBenefitsType.additional_holiday]: 'dodatkowy urlop',
  [SocialSecurityBenefitsType.rehabilitation_allowance]:
    'zasiłek rehabilitacyjny',
} as const;

export const BAR_HEIGHT_OFF_SET = 10;

export const SCROLL_OFF_SET_HEIGHT = 400;

export const MID_DAY_HOUR = 11;

export const NUMBER_OF_HOURS_CHART_SHIFTED_DATE_FROM = 1;

export const NUMBER_OF_HOURS_CHART_SHIFTED_DATE_TO = 0.75;

export type Nullable<T> = T | null;

import { createSlice } from '@reduxjs/toolkit';
import {
  fetchAdditionalHolidayTypes,
  addAdditionalHolidayType,
  getAdditionalHolidayUserPool,
} from './thunks';
import { AdditionalHolidays } from 'types/additionalHoliday';

const initialState: AdditionalHolidays = {
  additionalHolidays: [],
  isLoading: false,
  userAdditionalHolidaysPool: [],
};

const additionalHolidaySlice = createSlice({
  name: 'additionalHoliday',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdditionalHolidayTypes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAdditionalHolidayTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.additionalHolidays = action?.payload ?? [];
      })
      .addCase(fetchAdditionalHolidayTypes.rejected, (state) => {
        state.isLoading = false;
        state.additionalHolidays = [];
      })

      .addCase(getAdditionalHolidayUserPool.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAdditionalHolidayUserPool.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userAdditionalHolidaysPool = action?.payload ?? [];
      })
      .addCase(getAdditionalHolidayUserPool.rejected, (state) => {
        state.isLoading = false;
        state.userAdditionalHolidaysPool = [];
      })

      .addCase(addAdditionalHolidayType.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addAdditionalHolidayType.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.additionalHolidays = [
            ...state.additionalHolidays,
            action.payload,
          ];
        }
      })
      .addCase(addAdditionalHolidayType.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default additionalHolidaySlice.reducer;

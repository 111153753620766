import { FC } from 'react';
import {
  HStack,
  Image,
  Link,
  Spacer,
  useDisclosure,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Heading,
  Icon,
} from '@chakra-ui/react';
import {
  MdMenu,
  MdOutlineAdminPanelSettings,
  MdPerson,
  MdSentimentVerySatisfied,
  MdMenuBook,
  MdToday,
  MdOutlineCameraAlt,
  MdOutlineGroups,
} from 'react-icons/md';
import whiteRevolveLogoUrl from '../../../images/assets/whiteRevolveLogo.svg';
import { LayoutBackground } from './LayoutBackground';
import { useAppSelector } from '../../../store';
import {
  selectCurrentUser,
  selectIsCompanyAdmin,
} from '../../../redux/user/selectors';
import { LogoutButton } from '../../common/LogoutButton';
import { DrawerLink } from './DrawerLink';
import { DrawerCloseIcon } from './DrawerCloseIcon';
import {
  calendarAdminPath,
  calendarUserPath,
  oldIntranetUrl,
} from '../../../utils/paths';

interface Props {
  isFullBackground?: boolean;
}

export const Header: FC<Props> = ({ isFullBackground }) => {
  const currentUser = useAppSelector(selectCurrentUser);
  const isCompanyAdmin = useAppSelector(selectIsCompanyAdmin);
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <HStack
        as="header"
        color="light"
        bg="dark"
        h={['3.5rem', null, '5rem']}
        px={['1rem', null, '4rem']}
      >
        <Link href={oldIntranetUrl}>
          <Image
            w={['5rem', null, '12.25rem']}
            h={['1.5625rem', null, '3.8125rem']}
            src={whiteRevolveLogoUrl}
            alt="Revolve healthcare"
          />
        </Link>
        <Spacer />
        {currentUser && (
          <HStack>
            <Heading
              fontWeight={300}
              fontSize={['size6', null, 'size10']}
              textTransform="uppercase"
              paddingRight="0.5rem"
            >{`Cześć ${currentUser.firstName}!`}</Heading>
            <IconButton
              onClick={onOpen}
              aria-label="open-drawer"
              icon={
                <Icon
                  width="2.25rem"
                  height="1.875rem"
                  preserveAspectRatio="none"
                  color="light"
                  as={MdMenu}
                />
              }
              minWidth="0"
              width="2.25rem"
              height="1.875rem"
              bg="transparent"
              _hover={{ bg: 'transparent' }}
              _focus={{ bg: 'transparent' }}
            />
          </HStack>
        )}
        {currentUser && (
          <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent
              bg="drawerBackground"
              minWidth="21.875rem"
              maxWidth="21.875rem"
            >
              <DrawerHeader
                display="flex"
                flexDirection="column"
                paddingInlineStart="none"
                paddingInlineEnd="none"
                pt="none"
                pb="none"
                height="fit-content"
                color="light"
              >
                <IconButton
                  alignSelf="flex-end"
                  onClick={onClose}
                  aria-label="close-drawer"
                  icon={<DrawerCloseIcon />}
                  minWidth="none"
                  width="1.5rem"
                  height="1.5rem"
                  margin="0.5rem"
                  color="light"
                  bg="transparent"
                  _hover={{ bg: 'transparent' }}
                  _focus={{ bg: 'transparent' }}
                />
              </DrawerHeader>

              <DrawerBody color="light" px="1.725rem" pt="1.125rem">
                <Heading
                  alignSelf="flex-start"
                  fontWeight={300}
                  fontSize="size10"
                  textTransform="uppercase"
                  pb="1rem"
                  px="0.75rem"
                  lineHeight="1"
                >{`Cześć ${currentUser.firstName}!`}</Heading>
                <DrawerLink
                  destination={`${oldIntranetUrl}/profile`}
                  label="UZUPEŁNIJ PROFIL"
                  linkIcon={MdPerson}
                  isLinkExternal={true}
                  onLinkClick={onClose}
                />
                <DrawerLink
                  destination={calendarUserPath}
                  label="KALENDARZ URLOPÓW"
                  linkIcon={MdToday}
                  onLinkClick={onClose}
                />
                {isCompanyAdmin && (
                  <DrawerLink
                    destination={calendarAdminPath}
                    label="PANEL ADMINISTRATORA"
                    linkIcon={MdOutlineAdminPanelSettings}
                    onLinkClick={onClose}
                  />
                )}
                <DrawerLink
                  destination={`${oldIntranetUrl}/kudos`}
                  label="KUDOS BOX"
                  linkIcon={MdSentimentVerySatisfied}
                  isLinkExternal={true}
                  onLinkClick={onClose}
                />
                <DrawerLink
                  destination={`${oldIntranetUrl}/send-photo`}
                  label="WYŚLIJ ZDJĘCIE"
                  linkIcon={MdOutlineCameraAlt}
                  isLinkExternal={true}
                  onLinkClick={onClose}
                />
                <DrawerLink
                  destination={`${oldIntranetUrl}/projects-and-roles`}
                  label="PROJEKTY I ROLE"
                  linkIcon={MdOutlineGroups}
                  isLinkExternal={true}
                  onLinkClick={onClose}
                />
                <DrawerLink
                  destination="https://drive.google.com/drive/folders/1dPEDjQ8jl4ypopSpL_s9dSHvwPXn-its"
                  label="CULTURE BOOKS"
                  linkIcon={MdMenuBook}
                  isLinkExternal={true}
                  openInNewTab={true}
                  onLinkClick={onClose}
                />

                <LogoutButton />
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        )}
      </HStack>
      {!isFullBackground && <LayoutBackground />}
    </>
  );
};
